import React from 'react'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'

const Component = () => {
  return (
    <section className='block-404'>
      <div className='container'>
        <Inview className='fade-in-up'>
          <div className='text'>
            <h4 className='h4'>404: 没有找到页面</h4>
            <p>
              <LinkWrap to='/' className='btn--text btn--text--large'>
                返回首页
              </LinkWrap>
            </p>
          </div>
        </Inview>
      </div>
    </section>
  )
}

export default Component
