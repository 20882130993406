import React from 'react'
import Seo from 'components/Seo'
import Block404 from 'components/blocks/Block404'
import Footer from 'components/Footer'

const NotFoundPage = () => {
  const seoContent = {
    titleFull: `404: 没有找到页面`,
  }
  return (
    <div>
      <Seo content={seoContent} />
      <Block404 />
      <Footer showLogos={false} />
    </div>
  )
}

export default NotFoundPage
